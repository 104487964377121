<template>
  <div class="min-h-screen h-full bg-[#F7FBFE]">
    <TsHeaderCheckout />

    <div>
      <slot />
    </div>

    <TsFooterCheckout />
  </div>
</template>
